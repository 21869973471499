import { isMobile } from '../utils/index'
export default {
  address: '台北市中山區新生北路二段76巷10號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3614.309046954436!2d121.52519106903382!3d25.057512371674253!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a95d6a059ed5%3A0xbb5a898e1e245fdf!2zMTA0OTHlj7DljJfluILkuK3lsbHljYDmlrDnlJ_ljJfot6_kuozmrrU3NuW3tzEw6Jmf!5e0!3m2!1szh-TW!2stw!4v1644826953519!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/fnmvdCnLXQCgfKGJA',
  phone: '02-2541-0606',
  fbLink: 'https://www.facebook.com/中山直美-115702496966413',
  fbMessage: 'https://m.me/115702496966413',
  caseName: '中山直美',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '鐵冠建設股份有限公司',],
    ['建築設計', '鄭復綱建築師事務所',],
    ['工程營造', '昌譽營造股份有限公司',],
    ['公設擘劃', '紅林室內裝修設計有限公司',],
    ['基地面積', '約197坪',],
    ['樓層規劃', '地上10層/地下3層',],
    ['坪數規劃', '23-28坪',],
    ['建築代銷', '君頤廣告',],
  //  ['經  紀  人 ', '呂佳縈',],
    ['座落位置', '台北市中山區新生北路二段76巷10-20號',],
  ],

  gtmCode: ['WX44LWQ','WW77D4L'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '立即預約直賞' : '立即預約直賞',
    subTitle: '目前僅提供預約賞屋，請先聯繫專人服務'
  }
}
